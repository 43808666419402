<template>
  <div>
    <b-card bg-variant="Success" class="text-center" v-if="submitted">
        <b-button
        variant="outline-primary"
        :to="{ name: 'crm-utenti-index' }"
        >
            <feather-icon
                icon="CornerUpLeftIcon"
                size="16"
                class="mr-1"
            />Lista Utenti
        </b-button>
    </b-card>

    <b-card border-variant="danger" v-if="errore_caricamento">
      <b-row class="match-height">
        <b-col
          md="8"
        >
          <h4 class="mt-0 mb-0 text-danger">Errore durante il caricamento delle informazioni iniziali</h4>
          <p>Ti preghiamo di segnalare il problema all'assistenza tecnica, grazie</p>
        </b-col>
        <b-col 
          md="4"
          class="text-center"
        >
          <b-button
          variant="primary"
          :to="{ name: 'crm-utenti-index' }"
          >
              <feather-icon
                  icon="CornerUpLeftIcon"
                  size="16"
                  class="mr-1"
              />Lista Utenti
          </b-button>
        </b-col>
      </b-row>
        
    </b-card>
    
    <div class="text-center my-3" v-if="Caricato == false">
        <b-spinner
            variant="primary"
            style="width: 3rem; height: 3rem;"
            class="mr-1"
            label="loading"
        />
    </div>

    <div v-if="Caricato">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-tabs pills>
            <b-tab active>
              <template #title>
                <feather-icon
                  icon="UserIcon"
                  size="16"
                  class="mr-0 mr-sm-50"
                />
                <span class="d-none d-sm-inline">Dettagli</span>
              </template>
              <b-card bg-variant="Default">
                <b-row>
                  <b-col md="4" lg="3">
                    <b-form-group label="Nome *" label-for="name">
                        <validation-provider
                        #default="{ errors }"
                        name="Nome"
                        rules="required"
                        >
                        <b-form-input
                            v-model="campiform.user.name"
                            :state="errors.length > 0 ? false:null"
                            placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4" lg="3">
                    <b-form-group label="Cognome *" label-for="Surname">
                        <validation-provider
                        #default="{ errors }"
                        name="Cognome"
                        rules="required"
                        >
                        <b-form-input
                            v-model="campiform.user.surname"
                            :state="errors.length > 0 ? false:null"
                            placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="4" lg="3">
                    <b-form-group label="Email *" label-for="email">
                        <validation-provider
                        #default="{ errors }"
                        name="Email dell'utente"
                        rules="required|email"
                        >
                        <b-form-input
                            v-model="campiform.user.email"
                            :state="errors.length > 0 ? false:null"
                            placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4" lg="3">
                    &nbsp;
                  </b-col>
                  <b-col md="4" lg="3">
                    <b-form-group label="Telefono" label-for="telefono">
                        <b-form-input
                            v-model="campiform.user.phone"
                            placeholder=""
                        />
                    </b-form-group>
                  </b-col>
                  <b-col md="4" lg="3">
                    <b-form-group label="Cellulare" label-for="Cellulare">
                        <b-form-input
                            v-model="campiform.user.mobile_phone"
                            placeholder=""
                        />
                    </b-form-group>
                  </b-col>
                  <b-col md="4" lg="3">
                    <b-form-group label="Sesso *" label-for="gender_type_id">
                        <b-form-select
                            v-model="campiform.user.gender_type_id"
                            :options="options_gender"
                        />
                    </b-form-group>
                  </b-col>
                  <b-col md="4" lg="3">
                    <b-form-group label="Ruolo *" label-for="role_id">
                        <validation-provider
                        #default="{ errors }"
                        name="Ruolo"
                        rules="required"
                        >
                        <b-form-select
                            v-model="campiform.user.role_id"
                            :options="options_role"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>

              </b-card>
            </b-tab>

            <b-tab>
              <template #title>
                <feather-icon
                  icon="InfoIcon"
                  size="16"
                  class="mr-0 mr-sm-50"
                />
                <span class="d-none d-sm-inline">Contratto</span>
              </template>
              <b-card bg-variant="Default">
                <b-row>
                  <b-col md="2" lg="4">
                    <b-form-group label="Cassa" label-for="cash_id">
                      <b-form-select
                        v-model="campiform.cash_id"
                        :options="options_cassa"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="2" lg="4">
                    <b-form-group label="Ritenuta d'acconto" label-for="withholding_tax_id">
                      <b-form-select
                        v-model="campiform.withholding_tax_id"
                        :options="options_ritenuta_acconto"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="2" lg="4">
                    <b-form-group label="IBAN" label-for="iban">
                      <b-form-input
                        v-model="campiform.iban"
                        placeholder=""
                      />
                    </b-form-group>                        
                  </b-col>
                  <b-col md="2" lg="4">
                    <b-form-group label="BIC" label-for="bic">
                      <b-form-input
                        v-model="campiform.bic"
                        placeholder=""
                      />
                    </b-form-group>                        
                  </b-col>
                  <b-col md="2" lg="4">
                    <b-form-group label="Banca" label-for="bank">
                      <b-form-input
                        v-model="campiform.bank"
                        placeholder=""
                      />
                    </b-form-group>                        
                  </b-col>
                  <b-col md="2" lg="4">
                    <b-form-group label="€ Fisso Mensile" label-for="fixed_monthly">
                      <b-form-input
                        type="number"
                        v-model="campiform.fixed_monthly"
                        placeholder=""
                      />
                    </b-form-group>                        
                  </b-col>
                  <b-col md="2" lg="4">
                    <b-form-group label="% Provvigioni Sulle Vendite (Cliente nuovo)" label-for="sale_commissions_new">
                      <b-form-input
                        type="number"
                        v-model="campiform.sale_commissions_new"
                        placeholder=""
                      />
                    </b-form-group>                        
                  </b-col>
                  <b-col md="2" lg="4">
                    <b-form-group label="% Provvigioni Sulle Vendite (Cliente vecchio)" label-for="sales_commissions_old">
                      <b-form-input
                        type="number"
                        v-model="campiform.sale_commissions_old"
                        placeholder=""
                      />
                    </b-form-group>                        
                  </b-col>
                  <b-col md="2" lg="4">
                    <b-form-group label="% Gettone immediato alla vendita (Cliente nuovo)" label-for="sale_token_new">
                      <b-form-input
                        type="number"
                        v-model="campiform.sale_token_new"
                        placeholder=""
                      />
                    </b-form-group>                        
                  </b-col>
                  <b-col md="2" lg="4">
                    <b-form-group label="% Gettone immediato alla vendita (Cliente vecchio)" label-for="sale_token_old">
                      <b-form-input
                        type="number"
                        v-model="campiform.sale_token_old"
                        placeholder=""
                      />
                    </b-form-group>                        
                  </b-col>
                  

                </b-row>
              </b-card>
            </b-tab>
          </b-tabs>

          <b-row>
              <b-col md="12" class="text-center">
              <b-button
                  variant="primary"
                  type="submit"
                  :disabled="action_press"
                  @click.prevent="validationForm()"
              >
                  <div v-if="action_press">Salvaggio in corso <b-spinner small class="ml-1" /></div>
                  <div v-else>Salva</div>           
              </b-button>
              </b-col>
          </b-row>
          
        </b-form>
      </validation-observer>
    </div>
      
  </div>
</template>

<script>
import router from '@/router'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import it from 'vee-validate/dist/locale/it.json'
import { BTabs, BTab, BRow, BCol, BCard, BCardText, BSpinner, BButton, BFormGroup, BForm, BInputGroup, BFormInput, BInputGroupPrepend, BInputGroupAppend, BDropdownDivider, BFormSelect } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueAutosuggest } from 'vue-autosuggest'
import {
  required, email, digits, alphaDash, length,
} from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    it,
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard, 
    BCardText, 
    BSpinner, 
    BButton, 
    BForm,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BDropdownDivider,
    BFormSelect,

    VueAutosuggest,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,     
  },
  data() {
    return {
      campiform: {
        iban: '',
        bank: '',
        bic: '',
        fixed_monthly: '',
        sale_commissions_new: '',
        sale_commissions_old: '',
        sale_token_new: '',
        sale_token_old: '',
        cash_id: 1,
        withholding_tax_id: 1,
        user: { },
      },
      required,
      email,
      alphaDash, 
      digits,
      length,
      options_gender: [ ],
      options: [ ],
      options_province: [ ],
      options_role: [ ],
      options_regine_iva: [ ],
      options_cassa: [ ],
      options_ritenuta_acconto: [ ],
      Caricato: false,
      errore_caricamento: false,
      submitted: false,
      action_press: false,

      datasuggest: [],
      filteredOptions: [],
      limit: 10,
      selected: null,
        
    }
  },
  created() {
    //errori validazione in italiano
    localize({
      it: {
        messages: it.messages,
      },
    })

    const genderPromise = this.$http.get('api/fs/gender/list')    
    const rolePromise = this.$http.get('api/fs/role/list')
    const vatSchemePromise = this.$http.get('api/fs/employee/vat-scheme/list')
    const cassaPromise = this.$http.get('api/fs/employee/cash/list')
    const ritenutaAccontoPromise = this.$http.get('api/fs/employee/withholding-tax/list')
    const infoEmployeePromise = this.$http.get('api/crm/employee/card/'+router.currentRoute.params.id_riga)

    Promise.all([genderPromise, rolePromise, vatSchemePromise, cassaPromise, ritenutaAccontoPromise, infoEmployeePromise]).then(response => {
      if(response[0].data.statusCode===200){
        //caricamento lista nazioni
        this.options = response[0].data.reply

        if(response[1].data.statusCode===200){
          //caricamento lista province italiane
          this.options_province = response[1].data.reply

          if(response[0].data.statusCode===200){
            //caricamento lista gender
            this.options_gender = response[0].data.reply
            
            if(response[1].data.statusCode===200){
              //caricamento lista ruoli
              this.options_role = response[1].data.reply

              if(response[2].data.statusCode===200){
                //caricamento lista regime iva
                this.options_regine_iva = response[2].data.reply

                if(response[3].data.statusCode===200){
                  //caricamento lista cassa
                  this.options_cassa = response[3].data.reply

                  if(response[4].data.statusCode===200){
                    //caricamento lista ritenuta acconto
                    this.options_ritenuta_acconto = response[4].data.reply

                    if(response[5].data.statusCode===200){
                    //form dati employee (+ user)
                    this.campiform = response[5].data.reply.employeeData

                    //selezione del ruolo nel relativo select
                    var nome_ruolo = this.campiform.user.role
                    var role_id = this.options_role.filter(function(elem){
                      if(elem.text == nome_ruolo){
                          return elem
                      }
                    })
                    this.campiform.user.role_id = role_id[0].value;

                    //fine
                    this.Caricato = true;
                    this.errore_caricamento = false;
                  } else {
                    this.Caricato = false;
                    this.errore_caricamento = true;
                  }
                    
                  } else {
                    this.Caricato = false;
                    this.errore_caricamento = true;
                  } 
                } else {
                  this.Caricato = false;
                  this.errore_caricamento = true;
                }                
              } else {
                this.Caricato = false;
                this.errore_caricamento = true;
              }
            } else {
              this.Caricato = false;
              this.errore_caricamento = true;
            }
          } else {
            this.Caricato = false;
            this.errore_caricamento = true;
          }
        } else {
          this.Caricato = false;
          this.errore_caricamento = true;
        }
      } else {
        this.Caricato = false;
        this.errore_caricamento = true;
      }
    })
    
  },
  methods: {
    onInputChange(testo_ricerca) {
      if (testo_ricerca === '' || testo_ricerca === undefined) {
        return
      }

      /* filtra array nazioni */
      const filteredData = this.options.filter(item => item.text.toLowerCase().indexOf(testo_ricerca.toLowerCase()) > -1).slice(0, this.limit)
      
      //console.log("filteredData -----------")
      //console.log(filteredData)
      
      this.filteredOptions = [{
        data: filteredData,
      }]
    },
    renderSuggestion(suggestion) {
      //per gestire le voci restituite nella finestra auto-suggest
      return suggestion.item.text;
    },
    getSuggestionValue(suggestion) {
      //per gestire il testo che verra visualizzato dopo la selezione dela voce dalla finestra dell'auto-suggest
      console.log("suggestion")
      console.log(suggestion)
      
      return suggestion.item.text;
    },
    selectHandler(text){
      if (text === '' || text === undefined) {
        return
      } else {
        //associo id_nazione al parametro da passare all'api
        this.campiform.billing.country_id = text.item.value
        //console.log(this.campiform.billing)

        if(this.campiform.billing.country_id == this.id_italina){
          //forzo campo "province_id"
          this.campiform.billing.province_id = '0';
        } else {
          //province_id estera
          this.campiform.billing.province_id = '112';
        }
      }
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          //console.log(this.campiform)
          this.action_press = true

          this.$http.post('api/crm/user/employee/card/'+this.campiform.id, 
            this.campiform.user 
            ).then(response => { 
              console.log("risposta server --------")
              console.log(response.data)

              if((response.data.statusCode>=200) && (response.data.statusCode<=299)){
                //risposta positiva
                if(response.data.reply.userData.id > 0){
                  //cancello dati NON ncessari per il salvataggio del employee
                  delete(this.campiform.user);

                  //OK -> procedo al salvataggio dati "employee"
                  this.$http.post('api/crm/employee/card/'+this.campiform.id, 
                  this.campiform
                  ).then(response_employee => { 
                    console.log("risposta server --------")
                    console.log(response_employee.data)

                    if((response_employee.data.statusCode>=200) && (response_employee.data.statusCode<=299)){
                      //risposta positiva
                      this.$router.replace('/crm/utenti')
                        .then(() => {
                          this.$swal({
                              icon: 'success',
                              title: 'Utente aggiornato correttamente',
                              confirmButtonText: 'chiudi',
                              customClass: {
                              confirmButton: 'btn btn-success',
                              },
                          })
                        })
                      
                    } else {
                      //risposta negativa (errore sul server)
                      this.$swal({
                          icon: 'error',
                          title: 'Si è verificato un errore in Employee',
                          text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                          customClass: {
                          confirmButton: 'btn btn-outline-primary',
                          },
                      })
                        
                      this.action_press = false
                    }
                  }).catch(e => {
                    console.log(e);
                  });               


                } else {
                  //questo non dovrebbe mai accadere ... ma non si sa mai -> visualizzo risposta ma non faccio redirect
                  this.$swal({
                      icon: 'warning',
                      title: 'Si è verificato un errore User',
                      text: 'Salvataggio riuscito ma con errore '+response.data.statusCode+', ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                      customClass: {
                      confirmButton: 'btn btn-outline-primary',
                      },
                  })
                        
                  this.action_press = false
                }
                
              } else {
                //risposta negativa (errore sul server)
                if(response.data.reply.message != ''){
                  this.$swal({
                      icon: 'warning',
                      title: 'Si è verificato un errore',
                      text: response.data.reply.message,
                      customClass: {
                      confirmButton: 'btn btn-outline-primary',
                      },
                  })
                } else {
                  this.$swal({
                      icon: 'error',
                      title: 'Si è verificato un errore generico',
                      text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                      customClass: {
                      confirmButton: 'btn btn-outline-primary',
                      },
                  })
                }

                this.action_press = false
              }
            }).catch(e => {
              console.log(e);
            });

        }
      })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
